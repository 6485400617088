import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    Header,
    Multiselect,
    FormField,
    Input,
    Button,
    SpaceBetween,
    Form,
    Box
 } from "@cloudscape-design/components";

 import config from "../../config";

const StackerConfigDisplay = ({ token, customer, setCustomer, state, setState }) => {

    const [saveButtonLoading, setSaveButtonLoading] = useState(false);
    const [saveButtonState, setSaveButtonState] = useState();
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

    useEffect(() => {
        fetch(`${config.api_endpoint}/stacks`, {
            mode: "cors",
            method: "GET",
            headers: {
                "content-type": "application/json",
                "x-authorization": `Bearer ${token}`,
            },
        })
        .then((response) => response.json())
        .then((json) => {
            let variablesTmp = {}
            let stacksTmp = []
            let selectedStacksTmp = []
            let stacksByName = {}

            json["stacks"].map((stack) => {
                stack['templates'].map((template, key) => {
                    variablesTmp[template['name']] = []
                    return template['variables'].map((variable, key) => (
                        variablesTmp[template['name']][variable['name']] = variable['value']
                    ))
                })

                stack = {
                    'name': stack["name"],
                    'label': stack["pretty_name"],
                    'value': stack["name"],
                    'templates': stack['templates']
                }

                stacksByName[stack['name']] = stack
                return stacksTmp.push(stack)
            })

            customer['stacker_config']['stacks'].map((stack, key) => {

                for (const [key, value] of Object.entries(stack['variables'])) {

                  variablesTmp[stack['name']][key] = variablesTmp[stack['name']][key] = value
                }

                return selectedStacksTmp.push({
                    label: stacksByName[stack['parent_stack']]['label'],
                    name: stacksByName[stack['parent_stack']]['name'],
                    value: stack['parent_stack'],
                    templates: stacksByName[stack['parent_stack']]['templates']
                })
            })

            selectedStacksTmp = selectedStacksTmp.filter((value, index, self) =>
              index === self.findIndex((t) => (
                t.name === value.name
              ))
            )

            setState({
                stacks: stacksTmp,
                selectedStacks: selectedStacksTmp,
                variables: variablesTmp,
                customer_name: customer['customer_name'],
                customer_code: customer['customer_code'],
                aws_account_id: customer['aws_account_id']
            })

        })
        .catch((error) => console.error(error))

    }, [token, customer, setState]);


    const enableSaveButton = (e) => {
        setSaveButtonLoading(false)
        setSaveButtonState("primary")
        setSaveButtonDisabled(false)
    };

    const resetSaveButton = (e) => {
        setSaveButtonLoading(false)
        setSaveButtonState()
        setSaveButtonDisabled(true)
    };

    const handleSubmit = (e) => {
        e.preventDefault()

        setSaveButtonLoading(true)
        setSaveButtonState()

        // Swap the variable overrides into the form
        state['stacks'].map((stack, stackkey) => (
            stack['templates'].map((template, templatekey) => (
                template['variables'].map((variable, variablekey) => (
                    state['stacks'][stackkey]['templates'][templatekey]['variables'][variablekey]['value'] = state['variables'][template['name']][variable['name']]
                ))
            ))
        ))

        fetch(`${config.api_endpoint}/customers/${customer['customer_code']}`, {
            mode: "cors",
            method: "PUT",
            headers: {
                "content-type": "application/json",
                "x-authorization": `Bearer ${token}`
            },
            body: JSON.stringify(state),
        })
        .then((response) => response.json())
        .then((json) => {
            setCustomer(json['customer'])
            resetSaveButton()
        })
        .catch((error) => console.error(error));


    };



    return (
        <>

            <Header variant='h3'>
                Stacker Config
            </Header>

            <SpaceBetween size="xs">
                <form onSubmit={handleSubmit}>

                    <Form
                        actions={
                            <SpaceBetween direction='horizontal' size='xs'>
                                <Button loading={saveButtonLoading} variant={saveButtonState} disabled={saveButtonDisabled}>Save Changes</Button>
                            </SpaceBetween>
                        }
                    >
                        <Multiselect
                            selectedOptions={state.selectedStacks}
                            onChange={({ detail }) => {
                                setState({...state, selectedStacks: detail.selectedOptions})
                                enableSaveButton()
                            }}
                            options={state.stacks}
                            placeholder="Choose stacks"
                        />

                        {state.variables && state.selectedStacks.map((stack, key) => (

                            stack['templates'].map((template, key) => (

                                <Box key={key}>
                                    {(template['variables'].length > 0) && <>
                                    <br />
                                    <hr />
                                        <b key={"b" + key}>Variables for {template['name']}</b>

                                        {template['variables'].map((variable, key) => (
                                            <Box key={key}>
                                            <br />
                                            <FormField label={variable['name']} key={key}>

                                                <Input
                                                    value={state.variables[template['name']][variable['name']]}
                                                    onChange={(e) => {
                                                        state.variables[template['name']][variable['name']] = e.detail.value
                                                        setState({...state, variables: state.variables})
                                                        enableSaveButton()
                                                    }}
                                                />
                                            </FormField>
                                            </Box>

                                        ))}
                                    </>
                                }
                                </Box>

                            ))

                        ))}
                    </Form>
            </form>

            </SpaceBetween>


        </>

    );
};


const mapStateToProps = (state) => ({
    token: state.token.accessToken,
});

export default connect(mapStateToProps)(StackerConfigDisplay);