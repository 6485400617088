import * as React from 'react';
import { Link } from '@cloudscape-design/components';

export function getMatchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

function formatDate(date) {
  const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'long' });
  const timeFormatter = new Intl.DateTimeFormat('en-US', { timeStyle: 'short', hour12: false });
  return `${dateFormatter.format(date * 1000)}, ${timeFormatter.format(date * 1000)}`;
}

function createLabelFunction(columnName) {
  return ({ sorted, descending }) => {
    const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
    return `${columnName}, ${sortState}.`;
  };
}

export const columnDefinitions = [
  {
    id: 'customer_code',
    header: 'Customer Code',
    cell: item => <Link href={"/customers/" + item.customer_code + "/view"}>{item.customer_code}</Link>,
    ariaLabel: createLabelFunction('customer_code'),
    sortingField: 'customer_code',
    isRowHeader: true,
  },
  {
    id: 'customer_name',
    header: 'Customer Name',
    cell: item => item.customer_name,
    ariaLabel: createLabelFunction('Customer Name'),
    sortingField: 'customer_name',
  },
  {
    id: 'aws_account_id',
    header: 'AWS Account ID',
    cell: item => item.aws_account_id,
    ariaLabel: createLabelFunction('AWS Account ID'),
    sortingField: 'aws_account_id',
  },
  {
    id: 'modified_on',
    header: 'Last modified',
    cell: item => formatDate(item.modified_on),
    ariaLabel: createLabelFunction('Last modified'),
    sortingComparator: (a, b) => a.modified_on.valueOf() - b.modified_on.valueOf(),
  },
  {
    id: 'stacker_status',
    header: 'Stacker Status',
    cell: item => item.stacker_status,
    ariaLabel: createLabelFunction('Stacker Status'),
    sortingField: 'stacker_status',
  },
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: pageNumber => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

const pageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 10, label: '10 customers' },
    { value: 20, label: '20 customers' },
    { value: 50, label: '50 customers' },
  ],
};

const contentDisplayPreference = {
  title: 'Column preferences',
  description: 'Customize the columns visibility and order.',
  options: columnDefinitions.map(({ id, header }) => ({ id, label: header, alwaysVisible: id === 'customer_code' })),
};
export const collectionPreferencesProps = {
  pageSizePreference,
  contentDisplayPreference,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  title: 'Preferences',
};