import { useState, useEffect } from "react";
import { connect } from "react-redux";

import { useCollection } from '@cloudscape-design/collection-hooks';

import config from "../../config";

import {
	Box,
	Button,
	Header,
	SpaceBetween,
	Icon,
	CollectionPreferences,
	Pagination,
	Table,
	TextFilter,
} from "@cloudscape-design/components";

import { columnDefinitions, getMatchesCountText, paginationLabels, collectionPreferencesProps } from './StacksListTableConfig';


import AddStackModal from "./AddStackModal";

const StackList = ({ token, title }) => {
	const [stacks, setStacks] = useState([]);
	const [visible, setVisible] = useState(false);

	const EmptyState = ({ title, subtitle, action }) => {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {subtitle}
        </Box>
        {action}
      </Box>
    );
  }

  const [preferences, setPreferences] = useState({
    pageSize: 20,
    contentDisplay: [
      { id: 'name', visible: true },
      { id: 'templates', visible: true }
    ],
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    stacks,
    {
      filtering: {
        empty: <EmptyState title="No stacks" />,
        noMatch: (
          <EmptyState
            title="No matches"
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );

  const { selectedItems } = collectionProps;

	useEffect(() => {
		fetch(`${config.api_endpoint}/stacks`, {
			mode: "cors",
			method: "GET",
			headers: {
				"content-type": "application/json",
				"x-authorization": `Bearer ${token}`,
			},
		})
			.then((response) => response.json())
			.then((json) => setStacks(json["stacks"]))
			.catch((error) => console.error(error));
	}, [token]);

	return (
		<>
			<AddStackModal
                token={token}
                visible={visible}
                setVisible={setVisible}
                stacks={stacks}
                setStacks={setStacks}
            />

			<Table
	          {...collectionProps}
	          selectionType="multi"
	          header={
	          <Header
	            counter={
	              selectedItems.length
	                ? "(" + selectedItems.length + "/" + stacks.length + ")"
	                : "(" + stacks.length + ")"
	            }
	            actions={
	              <SpaceBetween
	                direction="horizontal"
	                size="xs"
	              >
	                <Button
                        onClick={() => {
                            setVisible(true);
                        }}
                    >
                        Create new Stack <Icon name='add-plus' />
                    </Button>
	                </SpaceBetween>
	            }
	          >
	            Stacks
	          </Header>
	          }
	          columnDefinitions={columnDefinitions}
	          columnDisplay={preferences.contentDisplay}
	          items={items}
	          pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
	          filter={
	            <TextFilter
	              {...filterProps}
	              countText={getMatchesCountText(filteredItemsCount)}
	              filteringAriaLabel="Filter stacks"
	            />
	          }
	          preferences={
	            <CollectionPreferences
	              {...collectionPreferencesProps}
	              preferences={preferences}
	              onConfirm={({ detail }) => setPreferences(detail)}
	            />
	          }
	        >

        </Table>
		</>

	);
};

const mapStateToProps = (state) => ({
	token: state.token.accessToken,
});

export default connect(mapStateToProps)(StackList);
