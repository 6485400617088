import { useState } from "react";
import { connect } from "react-redux";
import {
    Modal,
    Link,
    Icon,
    SpaceBetween,
    TextContent
 } from "@cloudscape-design/components";


import { ValueWithLabel } from "../../util";

const VariablesList = ({ variables, template_name }) => {

    const [visible, setVisible] = useState(false);

    return (
        <>

         <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            header={"Variables for " + template_name}
        >

            <SpaceBetween size='s'>
                <TextContent>
                    <p>This template has the following variables set:</p>
                </TextContent>
                {variables.map((variable, key) => (
                    <>
                    <ValueWithLabel key={key} label={variable['name']}>
                        Value: {variable['value'] || "No Default Value"}
                    </ValueWithLabel>
                    <hr />
                    </>
                ))}
            </SpaceBetween>

        </Modal>

        <Link
            onFollow={() => {
                setVisible(true);
            }}
        >
            View ({variables.length}) <Icon name='search' />
        </Link>
        </>

    );
};


export default connect()(VariablesList);
